// MapWithCircle.js
import React, { useState } from 'react';
import { MapContainer, TileLayer, Circle, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

// Default marker icon fix for React-Leaflet
import L from 'leaflet';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

const DefaultIcon = L.icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

const MapWithCircle = (location) => {
  const initialPosition = [51.505, -0.09]; // Default position 
  const [radius, setRadius] = useState(1000); // Default radius in meters
  const [radiusKM, setRadiusKM] = useState(1);

  const updateRadius = (value) => {
    setRadius(value*1000);
    setRadiusKM(value);
  }
    return (
    <div>
      <h2>Adjust Circle Radius</h2>
      <input
        type="range"
        min={1}
        max={100}
        value={radiusKM}
        onChange={(e) => updateRadius(e.target.value)}
      />
      <span>{radiusKM} KM</span>

      <MapContainer center={initialPosition} zoom={10} style={{ height: '60vh', width: '100%' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={initialPosition} />
        <Circle center={initialPosition} radius={parseInt(radius)} />
      </MapContainer>
    </div>
  );
};

export default MapWithCircle;
