import { postTrabajo } from "../helpers";
import Spinner from "./Spinner";
import { useState } from "react";

function Test () {

    const [loading, setLoading] = useState(true);

    async function loadTrabajos () {
        var trabajos = ['Director/a',
        'Subdirector/a',
        'Resp Recepción',
        'Recepcionista',
        'Ayd recepción',
        'Mozo/a equipaje',
        'Recepcionista noche',
        'Ayd Recepcionista noche',
        'Vigilante nocturno',
        'Administrativo/a',
        'Resp Reservas',
        'Contable/finanzas',
        'Comercial/Marketing',
        'Resp Bar',
        'Resp Comedor',
        'Camarero/a',
        'Ayd camarero/a',
        'Resp Buffet',
        'Pasavinos',
        'Bodeguero/a',
        'Almacenero/a',
        'Resp Cocina',
        'Cocinero/a',
        'Ayd cocina',
        'Cocina fría',
        'Cocina caliente',
        'Postres',
        'Friegaplatos',
        'Resp de limpieza',
        'Personal de limpieza',
        'Camararo/a habitaciones',
        'Resp Lavandería',
        'Personal lavandería',
        'Personal SPA y Estética',
        'Masajista',
        'Pelquero/a',
        'Esteticién',
        'Animador/a turístico',
        'Ayd Animador/a turístico',
        'Socorrista piscina',
        'Vigilante de piscina',
        'Resp Mantenimiento',
        'Ayd Mantenimiento',
        'Electricista - Fontanero/a']

        for (var n in trabajos) {
            await postTrabajo(trabajos[n],1)
        }
        setLoading(false);
    }
    if (loading) {
        //loadTrabajos();
        return (<Spinner/>)
    } else return (
        <span>TEST</span>        
    )
};


export default Test;