import { createContext, useContext } from "react";

export const AuthContext = createContext({
  user: undefined,
  isLoading: false,
  setUser: () => {},
  candidato: undefined,
  setCandidato: () => {},
  empresa: undefined,
  setEmpresa: () => {},
});

export const useAuthContext = () => useContext(AuthContext);
